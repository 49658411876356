import React from "react";
import { Card, CardBody, Col, Row } from "reactstrap";

import RadialChart1 from "./userpanelChart1";
import RadialChart2 from "./userpanelChart2";
import RadialChart3 from "./userpanelChart3";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

const UserPanel = () => {
  const state = useSelector((state) => state);
  return (
    <React.Fragment>
      <Row>
        <Col xl={6} sm={12}>
          <Link to="/apple-id">
            <Card>
              <CardBody>
                <div className="d-flex text-muted">
                  <div className="flex-shrink-0 me-3 align-self-center">
                    <div id="radialchart-1" className="apex-charts" dir="ltr">
                      <RadialChart1
                        pres={Math.floor(state.login?.user.soldPercentage)}
                      />
                    </div>
                  </div>

                  <div className="flex-grow-1 overflow-hidden  align-self-center">
                    <p className="mb-1">Apple IDs</p>
                    <h5 className="mb-3">
                      Accounts Number:{" "}
                      {state.login?.user.ApplesCount -
                        state.login?.user.ApplesSoldCount}
                    </h5>
                    <p className="text-truncate mb-0">
                      {/* <span className="text-success me-2">
                      {" "}
                      0.02%{" "}
                      <i className="ri-arrow-right-up-line align-bottom ms-1"></i>
                    </span>{" "}
                    From previous */}
                    </p>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Link>
        </Col>

        <Col xl={6} sm={12}>
          <Link to="/nfc-card">
            <Card>
              <CardBody>
                <div className="d-flex">
                  <div className="flex-shrink-0 me-3 align-self-center">
                    <RadialChart2
                      id="radialchart-2"
                      className="apex-charts"
                      dir="ltr"
                    />
                  </div>

                  <div className="flex-grow-1 overflow-hidden  align-self-center">
                    <p className="mb-1">NFC Cards</p>
                    <h5 className="mb-3">Cards: 1400</h5>
                    {/* <p className="text-truncate mb-0">
                    <span className="text-success me-2">
                      {" "}
                      1.7%{" "}
                      <i className="ri-arrow-right-up-line align-bottom ms-1"></i>
                    </span>{" "}
                    From previous
                  </p> */}
                  </div>
                </div>
              </CardBody>
            </Card>
          </Link>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default UserPanel;
