// import { createStore, applyMiddleware, compose } from "redux";
// import createSagaMiddleware from "redux-saga";
// import thunk from 'redux-thunk';
// import rootReducer from "./reducers";
// // import rootSaga from "./sagas";

// // const sagaMiddleware = createSagaMiddleware();
// const middlewares = [thunk];
// const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

// export function configureStore(initialState) {
//   const store = createStore(
//     rootReducer,
//     initialState,
//     composeEnhancers(applyMiddleware(...middlewares))
//   );
//   // sagaMiddleware.run(rootSaga);
//   return store;
// }

import { applyMiddleware, compose, createStore } from "redux";
// import thunk from "redux-thunk";
import rootReducer from "./reducers";
import { thunk } from "redux-thunk";

// const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const composeEnhancers =
  (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
      trace: true,
      traceLimit: 25,
    })) ||
  compose;

export const store = createStore(
  rootReducer,
  /* preloadedState, */ composeEnhancers(applyMiddleware(thunk))
);
