import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Alert,
  CardBody,
  Button,
  Label,
  Input,
  FormFeedback,
  Form,
} from "reactstrap";

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

//redux
import { useSelector, useDispatch } from "react-redux";

import withRouter from "../../components/Common/withRouter";

//Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb";

import avatar from "../../assets/images/users/avatar-1.jpg";
// actions
import {
  changePasswordUser,
  editProfile,
  resetProfileFlag,
} from "../../store/actions";

import { createSelector } from "reselect";

const UserProfile = () => {
  document.title = "Profile | Upzet - React Admin & Dashboard Template";

  const dispatch = useDispatch();

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      oldpassword: "",
      password: "",
    },
    validationSchema: Yup.object({
      oldpassword: Yup.string().required("Please Enter Your Old Password"),
      password: Yup.string().required("Please Enter Your New Password"),
    }),
    onSubmit: (values) => {
      dispatch(changePasswordUser(values));
    },
  });
  // Inside your component
  // const { error, success } = useSelector(userprofilepage);

  // useEffect(() => {
  //   if (localStorage.getItem("authUser")) {
  //     const obj = JSON.parse(localStorage.getItem("authUser"));
  //     if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
  //       setname(obj.displayName);
  //       setemail(obj.email);
  //       setidx(obj.uid);
  //     } else if (
  //       process.env.REACT_APP_DEFAULTAUTH === "fake" ||
  //       process.env.REACT_APP_DEFAULTAUTH === "jwt"
  //     ) {
  //       setname(obj.username);
  //       setemail(obj.email);
  //       setidx(obj.uid);
  //     }
  //     setTimeout(() => {
  //       dispatch(resetProfileFlag());
  //     }, 3000);
  //   }
  // }, [dispatch, success]);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumb title="Mega" breadcrumbItem="Profile" />

          <h4 className="card-title mb-4">Change Password</h4>

          <Card>
            <CardBody>
              <Form
                className="form-horizontal"
                onSubmit={(e) => {
                  e.preventDefault();
                  validation.handleSubmit();
                  return false;
                }}
              >
                <div className="mb-4">
                  <div className="field">
                    <input
                      name="oldpassword"
                      value={validation.values.oldpassword || ""}
                      type="password"
                      placeholder=""
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      invalid={
                        validation.touched.oldpassword &&
                        validation.errors.oldpassword
                          ? true
                          : false
                      }
                    />
                    <span class="label">Old Password</span>
                    {validation.touched.oldpassword &&
                    validation.errors.oldpassword ? (
                      <FormFeedback type="invalid">
                        <div> {validation.errors.oldpassword} </div>
                      </FormFeedback>
                    ) : null}
                  </div>
                </div>
                <div className="mb-4">
                  <div className="field">
                    <input
                      name="password"
                      value={validation.values.password || ""}
                      type="password"
                      placeholder=""
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      invalid={
                        validation.touched.password &&
                        validation.errors.password
                          ? true
                          : false
                      }
                    />
                    <span class="label">New Password</span>
                    {validation.touched.password &&
                    validation.errors.password ? (
                      <FormFeedback type="invalid">
                        <div> {validation.errors.password} </div>
                      </FormFeedback>
                    ) : null}
                  </div>
                </div>

                <div className="mt-4">
                  <Button type="submit" color="success">
                    Change Password
                  </Button>
                </div>
              </Form>
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(UserProfile);
