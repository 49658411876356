import React from "react";
import { Navigate, Route } from "react-router-dom";
import { useSelector } from "react-redux";

// import { useProfile } from "../Hooks/UserHooks";

const AuthProtected = (props) => {
  // const { userProfile, loading } = useProfile();
  const state = useSelector((state) => state);
  /*
    redirect is un-auth access protected routes via url
    */

  if (state.login.token == null || state.login.token == "") {
    return (
      <Navigate to={{ pathname: "/login", state: { from: props.location } }} />
    );
  }

  return <>{props.children}</>;
};

const AccessRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => {
        return (
          <>
            {" "}
            <Component {...props} />{" "}
          </>
        );
      }}
    />
  );
};

export { AuthProtected, AccessRoute };
