import React, { useRef } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Spinner,
  Button,
} from "reactstrap";
import { format } from "date-fns";
import AppleLogo from "./assets/Apple.png";
import megaLogo from "./assets/logo.png";
import { triggerBase64Download } from "react-base64-downloader";
import withRouter from "../../components/Common/withRouter";

import Api from "../../api/Api";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { toast } from "react-toastify";
import { useScreenshot } from "use-react-screenshot";

const Public = (props) => {
  document.title = "Profile | Upzet - React Admin & Dashboard Template";

  const [isLoading, setLoading] = React.useState(true);
  const [isError, setError] = React.useState(true);

  const [data, setData] = React.useState([]);

  const ref = useRef(null);
  const [image, takeScreenshot] = useScreenshot();
  const getImage = () => {
    takeScreenshot(ref.current);
  };

  React.useEffect(() => {
    if (image) {
      triggerBase64Download(image, "MegaAppleId");
    }
  }, [image]);

  React.useEffect(() => {
    setLoading(true);
    Api.get(window.location.pathname)
      .then((response) => {
        setData(response.data);
        setLoading(false);
      })
      .catch((error) => {
        toast.error("No Apple ID please check the url", {
          position: "top-center",
          autoClose: false,
          hideProgressBar: false,
          closeOnClick: false,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      });
  }, []);

  // const userprofilepage = createSelector(
  //   (state) => state.profile,
  //   (state) => ({
  //     error: state.error,
  //     success: state.success,
  //   })
  // );
  // Inside your component
  // const { error, success } = useSelector(userprofilepage);

  // useEffect(() => {
  //   if (localStorage.getItem("authUser")) {
  //     const obj = JSON.parse(localStorage.getItem("authUser"));
  //     if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
  //       setname(obj.displayName);
  //       setemail(obj.email);
  //       setidx(obj.uid);
  //     } else if (
  //       process.env.REACT_APP_DEFAULTAUTH === "fake" ||
  //       process.env.REACT_APP_DEFAULTAUTH === "jwt"
  //     ) {
  //       setname(obj.username);
  //       setemail(obj.email);
  //       setidx(obj.uid);
  //     }
  //     setTimeout(() => {
  //       dispatch(resetProfileFlag());
  //     }, 3000);
  //   }
  // }, [dispatch, success]);

  // const validation = useFormik({
  //   // enableReinitialize : use this flag when initial values needs to be changed
  //   enableReinitialize: true,

  //   initialValues: {
  //     username: name || "",
  //     idx: idx || "",
  //   },
  //   validationSchema: Yup.object({
  //     username: Yup.string().required("Please Enter Your UserName"),
  //   }),
  //   onSubmit: (values) => {
  //     dispatch(editProfile(values));
  //   },
  // });

  const copied = () => {
    toast.success("Text Copied", {
      position: "bottom-center",
      autoClose: 500,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: false,
      progress: undefined,
      theme: "colored",
    });
  };

  return (
    <React.Fragment>
      <div className="page-content" ref={ref}>
        {isLoading ? (
          <div style={{ textAlign: "center", marginTop: 200 }}>
            <Spinner type="grow">Loading...</Spinner>
          </div>
        ) : (
          <Container fluid>
            {/* <Breadcrumb title="Upzet" breadcrumbItem="Profile" /> */}
            <img
              height={200}
              src={`${
                data.image === "logo.png"
                  ? megaLogo
                  : `https://api.id-mega.com/public/storage/${data.image}`
              }`}
              className="mt-4 mb-4 ml-auto mr-auto d-flex"
              style={{
                marginLeft: "auto",
                marginRight: "auto",
                maxWidth: "100%",
                width: 400,
                height: "auto",
              }}
            />

            <p className="text-center mt-4 mb-4" style={{ fontSize: 16 }}>
              Account Number:{" "}
              <span
                style={{
                  padding: "5px 10px",
                  background: "#512874",
                  color: "#fff",
                  borderRadius: 5,
                }}
              >
                {data?.id}
              </span>
            </p>

            <Row className="justify-content-center">
              <Col md="6">
                <Card style={{ padding: 15 }}>
                  <CardBody>
                    <Row className="mb-3">
                      <div className="col-md-5">
                        <img
                          height={200}
                          src={AppleLogo}
                          className="mt-4 mb-4 ml-auto mr-auto d-flex hide-on-mobile"
                          style={{
                            marginLeft: "auto",
                            marginRight: "auto",
                            width: "50%",
                            height: "auto",
                          }}
                        />
                      </div>
                      <div className="col-md-7">
                        <h5>Apple ID:</h5>
                        <div className="public-apple mb-3">
                          <div className="public-apple-button">
                            <div className="d-block">
                              <h4>Email:</h4>
                              <span> {data.email}</span>
                            </div>
                            <CopyToClipboard text={data.email} onCopy={copied}>
                              <i className="ri-file-copy-line align-bottom "></i>
                            </CopyToClipboard>
                          </div>
                          <div className="public-apple-button">
                            <div className="d-block">
                              <h4>Password:</h4>
                              <span> {data.password}</span>
                            </div>
                            <CopyToClipboard
                              text={data.password}
                              onCopy={copied}
                            >
                              <i className="ri-file-copy-line align-bottom "></i>
                            </CopyToClipboard>
                          </div>
                          {data.birthday ? (
                            <div className="public-apple-button">
                              <div className="d-block">
                                <h4>Birthday:</h4>
                                <span>
                                  {" "}
                                  {format(
                                    new Date(data.birthday),
                                    "yyyy-MM-dd"
                                  )}
                                </span>
                              </div>
                              <CopyToClipboard
                                text={format(
                                  new Date(data.birthday),
                                  "yyyy-MM-dd"
                                )}
                                onCopy={copied}
                              >
                                <i className="ri-file-copy-line align-bottom "></i>
                              </CopyToClipboard>
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </Row>
                    <Row>
                      <div className="col-12 mb-3">
                        <h5>Security Question:</h5>
                        <div className="public-apple2 mb-3">
                          <div className="public-apple-button2">
                            <div className="d-block">
                              <h4>{data.question1}</h4>
                              <span>{data.answer1}</span>
                            </div>
                            <CopyToClipboard
                              text={data.answer1}
                              onCopy={copied}
                            >
                              <i className="ri-file-copy-line align-bottom "></i>
                            </CopyToClipboard>
                          </div>
                          <div className="public-apple-button2">
                            <div className="d-block">
                              <h4>{data.question2}</h4>
                              <span>{data.answer2}</span>
                            </div>
                            <CopyToClipboard
                              text={data.answer2}
                              onCopy={copied}
                            >
                              <i className="ri-file-copy-line align-bottom "></i>
                            </CopyToClipboard>
                          </div>
                          <div className="public-apple-button2">
                            <div className="d-block">
                              <h4>{data.question3}</h4>
                              <span>{data.answer3}</span>
                            </div>
                            <CopyToClipboard
                              text={data.answer3}
                              onCopy={copied}
                            >
                              <i className="ri-file-copy-line align-bottom "></i>
                            </CopyToClipboard>
                          </div>
                        </div>
                        {/* <div className="public-input mb-3">
                        <div className="public-input-info">
                          <h4>{data.question2}</h4>
                          <span>{data.answer2}</span>
                        </div>
                        <div className="public-input-button">
                          <CopyToClipboard text={data.answer2}>
                            <i className="ri-file-copy-line align-bottom "></i>
                          </CopyToClipboard>
                        </div>
                      </div>
                      <div className="public-input mb-3">
                        <div className="public-input-info">
                          <h4>{data.question3}</h4>
                          <span>{data.answer3}</span>
                        </div>
                        <div className="public-input-button">
                          <CopyToClipboard text={data.answer3}>
                            <i className="ri-file-copy-line align-bottom "></i>
                          </CopyToClipboard>
                        </div>
                      </div> */}
                      </div>
                      {data.shownote ? (
                        <div className="col-12">
                          <h5>Note:</h5>
                          <div className="public-apple mb-4">
                            <div className="public-apple-button">
                              <CopyToClipboard text={data.note} onCopy={copied}>
                                <>
                                  {data.note}{" "}
                                  <i className="ri-file-copy-line align-bottom "></i>
                                </>
                              </CopyToClipboard>
                            </div>
                          </div>
                        </div>
                      ) : null}
                      <p
                        className="text-right"
                        style={{
                          fontSize: 16,
                          textAlign: "right",
                          direction: "rtl",
                        }}
                      >
                        ملاحظة مهمة :⚠️ يستعمل فقط لحساب Apple Id لتنزيل
                        التطبيقات من متجر App Store ولا يستعمل كحساب i Cloud
                        كفالة مدى الحياة ليعمل كحساب Apple id ولسنا مسؤولين على
                        الإطلاق عن استعماله كحساب i Cloud
                      </p>

                      <Button
                        color="success"
                        className="add-btn"
                        onClick={getImage}
                        id="create-btn"
                      >
                        <i className="ri-screenshot-2-line align-bottom me-1"></i>{" "}
                        Save As Image
                      </Button>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>

            {/* <Row className="justify-content-center">
            <Col md="7">
              <Card>
                <CardBody>
                  <img
                    height={200}
                    src={`https://api.id-mega.com/public/storage/${data?.image}`}
                    className="mt-4 mb-4 ml-auto mr-auto d-flex"
                    style={{
                      marginLeft: "auto",
                      marginRight: "auto",
                      maxWidth: 400,
                      width: "100%",
                      height: "auto",
                    }}
                  />

                  <p className="text-center" style={{ fontSize: 16 }}>
                    Account Number: {data?.id}
                  </p>

                  <Row>
                    <Col md={6}>
                      <div className="public-input mb-3">
                        <div className="public-input-info">
                          <h4>Apple Id:</h4>
                          <span>{data.email}</span>
                        </div>
                        <div className="public-input-button">
                          <CopyToClipboard text={data.email}>
                            <i className="ri-file-copy-line align-bottom "></i>
                          </CopyToClipboard>
                        </div>
                      </div>
                    </Col>
                    <Col md={6}>
                      <div className="public-input mb-3">
                        <div className="public-input-info">
                          <h4>Password:</h4>
                          <span>{data.password}</span>
                        </div>
                        <div className="public-input-button">
                          <CopyToClipboard text={data.password}>
                            <i className="ri-file-copy-line align-bottom "></i>
                          </CopyToClipboard>
                        </div>
                      </div>
                    </Col>
                  </Row>

                  <Row>
                    <Col md={6}>
                      <div className="public-input mb-3">
                        <div className="public-input-info">
                          <h4>Question 1:</h4>
                          <span>{data.question1}</span>
                        </div>
                        {/* <div className="public-input-button">
                          <CopyToClipboard text={data.question1}>
                            <i className="ri-file-copy-line align-bottom "></i>
                          </CopyToClipboard>
                  </div> 
                      </div>
                    </Col>
                    <Col md={6}>
                      <div className="public-input mb-3">
                        <div className="public-input-info">
                          <h4>Answer 1:</h4>
                          <span>{data.answer1}</span>
                        </div>
                        <div className="public-input-button">
                          <CopyToClipboard text={data.answer1}>
                            <i className="ri-file-copy-line align-bottom "></i>
                          </CopyToClipboard>
                        </div>
                      </div>
                    </Col>
                  </Row>

                  <Row>
                    <Col md={6}>
                      <div className="public-input mb-3">
                        <div className="public-input-info">
                          <h4>Question 2:</h4>
                          <span>{data.question2}</span>
                        </div>
                        {/* <div className="public-input-button">
                          <CopyToClipboard text={data.question2}>
                            <i className="ri-file-copy-line align-bottom "></i>
                          </CopyToClipboard>
                        </div>  
                      </div>
                    </Col>
                    <Col md={6}>
                      <div className="public-input mb-3">
                        <div className="public-input-info">
                          <h4>Answer 2:</h4>
                          <span>{data.answer2}</span>
                        </div>
                        <div className="public-input-button">
                          <CopyToClipboard text={data.answer2}>
                            <i className="ri-file-copy-line align-bottom "></i>
                          </CopyToClipboard>
                        </div>
                      </div>
                    </Col>
                  </Row>

                  <Row>
                    <Col md={6}>
                      <div className="public-input mb-3">
                        <div className="public-input-info">
                          <h4>Question 3:</h4>
                          <span>{data.question3}</span>
                        </div>
                        {/* <div className="public-input-button">
                          <CopyToClipboard text={data.question3}>
                            <i className="ri-file-copy-line align-bottom "></i>
                          </CopyToClipboard>
                        </div>  
                      </div>
                    </Col>
                    <Col md={6}>
                      <div className="public-input mb-3">
                        <div className="public-input-info">
                          <h4>Answer 3:</h4>
                          <span>{data.answer3}</span>
                        </div>
                        <div className="public-input-button">
                          <CopyToClipboard text={data.answer3}>
                            <i className="ri-file-copy-line align-bottom "></i>
                          </CopyToClipboard>
                        </div>
                      </div>
                    </Col>
                  </Row>

                  <Row>
                    {data.birthday ? (
                      <Col md={12}>
                        <div className="public-input mb-3">
                          <div className="public-input-info">
                            <h4>Birthday:</h4>
                            <span>
                              {format(new Date(data.birthday), "yyyy-MM-dd")}
                            </span>
                          </div>
                          <div className="public-input-button">
                            <CopyToClipboard
                              text={format(
                                new Date(data.birthday),
                                "yyyy-MM-dd"
                              )}
                            >
                              <i className="ri-file-copy-line align-bottom "></i>
                            </CopyToClipboard>
                          </div>
                        </div>
                      </Col>
                    ) : null}
                    {data.note !== null ? (
                      <Col md={12}>
                        <div className="public-input mb-3">
                          <div className="public-input-info">
                            <h4>Note:</h4>
                            <span>{data.note}</span>
                          </div>
                          <div className="public-input-button">
                            <CopyToClipboard text={data.note}>
                              <i className="ri-file-copy-line align-bottom "></i>
                            </CopyToClipboard>
                          </div>
                        </div>
                      </Col>
                    ) : (
                      ""
                    )}
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row> */}
            {/* 
          <Row>
            <Col lg="12">
              {error && error ? (
                <Alert color="danger">
                  <div>{error}</div>
                </Alert>
              ) : null}
              {success ? (
                <Alert color="success">
                  <div>{success}</div>
                </Alert>
              ) : null}

              <Card>
                <CardBody>
                  <div className="d-flex">
                    <div className="ms-3">
                      <img
                        src={avatar}
                        alt=""
                        className="avatar-md rounded-circle img-thumbnail"
                      />
                    </div>
                    <div className="flex-grow-1 align-self-center">
                      <div className="text-muted">
                        <h5>{name}</h5>
                        <p className="mb-1">{email}</p>
                        <p className="mb-0">Id no: #{idx}</p>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row> */}

            {/* <h4 className="card-title mb-4">Change User Name</h4> */}

            {/* <Card>
            <CardBody>
              <Form
                className="form-horizontal"
                onSubmit={(e) => {
                  e.preventDefault();
                  validation.handleSubmit();
                  return false;
                }}
              >
                <div className="form-group">
                  <Label className="form-label">User Name</Label>
                  <Input
                    name="username"
                    // value={name}
                    className="form-control"
                    placeholder="Enter User Name"
                    type="text"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.username || ""}
                    invalid={
                      validation.touched.username && validation.errors.username
                        ? true
                        : false
                    }
                  />
                  {validation.touched.username && validation.errors.username ? (
                    <FormFeedback type="invalid">
                      <div>{validation.errors.username}</div>
                    </FormFeedback>
                  ) : null}
                  <Input name="idx" value={idx} type="hidden" />
                </div>
                <div className="text-center mt-4">
                  <Button type="submit" color="danger">
                    Update User Name
                  </Button>
                </div>
              </Form>
            </CardBody>
          </Card> */}
          </Container>
        )}
      </div>
    </React.Fragment>
  );
};

export default withRouter(Public);
